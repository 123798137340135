$primary: #227066 !default;
$secondary: #333131 !default;
$font-primary: #171717 !default;
$background: #fff !default;
$primary-disabled: #a7c6c2 !default;
$btn-hover: #0d5b51 !default;
$btn-pressed-secondary: #4e8d85 !default;
$black: #000 !default;

$dark-charcoal: #343132 !default;
$very-dark-grayish-red: #333131 !default;

$green-cream: #a9edce !default;
$light-green: #d3e2e0 !default;
$circle-family: #3b3ba8 !default;
$circle-track-family: #b4c4fc !default;
$gray15: #262626 !default;
$mauve: #b3a9a3 !default;
$panache: #f5f0ee !default;
$dark-grayish: #333131 !default;
$elm-green: #5d585a !default;
$snow: #fcfafa !default;
$sweet-cream: #eee7e4 !default;
$granite-gray: #696363 !default;
$very-light-gray: #ececec !default;
$lush-aqua: #0e406a !default;
$suva-grey: #8d8585 !default;
$red: #ed0707 !default;
$white: #fff !default;
$neutral: #b3a8a2 !default;
$abbey: #4b4849 !default;
$linkblue: #0e406a !default;
$lavender: #e1e1fe !default;
$focus-color: #0467eb;
$linkblue-focus: #0467eb;
$grid-gutter-width: 2rem;
$black: #000;
$black700: #4b4849;
$global-error-text: #991b1b;
$error-text: #dc2626;

@import "~bootstrap/scss/bootstrap.scss";

@font-face {
  font-family: RedHatDisplay_body;
  src: url("../fonts/RedHatDisplay-Regular.ttf") format("truetype");
}

@font-face {
  font-family: RedHatDisplay_bold;
  src: url("../fonts/RedHatDisplay-Bold.ttf") format("truetype");
}

@font-face {
  font-family: RedHatDisplay_medium;
  src: url("../fonts/RedHatDisplay-Medium.ttf") format("truetype");
}

@font-face {
  font-family: RedHatDisplay_semibold;
  src: url("../fonts/RedHatDisplay-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: RedHatDisplay_italic;
  src: url("../fonts/RedHatDisplay-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "Literata_regular";
  src: url("../fonts/Literata_18pt-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Literata_medium";
  src: url("../fonts/Literata_18pt-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Literata_semibold";
  src: url("../fonts/Literata_18pt-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Literata_bold";
  src: url("../fonts/Literata_18pt-Bold.ttf") format("truetype");
}
:root {
  --bs-body-font-family: "RedHatDisplay_body", Arial, Helvetica, sans-serif !important;
}
body {
  font-family: "RedHatDisplay_body", Arial, Helvetica, sans-serif !important;
  color: $dark-grayish !important;
  font-synthesis: none;
  letter-spacing: 0.02rem;
}
.container-fluid {
  max-width: 1440px;
  padding: 0 2.5rem;
}
.redhat_regular {
  font-family: "RedHatDisplay_body", Helvetica, Arial, sans-serif !important;
  line-height: 1.5rem;
}

.redhat_medium {
  font-family: "RedHatDisplay_medium", Helvetica, Arial, sans-serif !important;
}

.redhat_bold {
  font-family: "RedHatDisplay_bold", Helvetica, Arial, sans-serif !important;
}

.redhat_semibold {
  font-family: "RedHatDisplay_semibold", Helvetica, Arial, sans-serif !important;
}

.redhat_italic {
  font-family: "RedHatDisplay_italic", Helvetica, Arial, sans-serif !important;
}

.literata_regular {
  font-family: "Literata_Regular", Georgia, Times, serif !important;
}
.literata_medium {
  font-family: "Literata_medium", Georgia, Times, serif !important;
}
.literata_semibold {
  font-family: "Literata_semibold", Georgia, Times, serif !important;
}
.literata_bold {
  font-family: "Literata_bold", Georgia, Times, serif !important;
}
.faq-accordion .accordion-menu-item {
  border-bottom: 1px solid $sweet-cream;
  background-color: $white;
}
.faq-accordion .accordion-menu-item .accordion-button {
  color: $primary;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
}
.faq-accordion .accordion-menu-item .accordion-button,
.faq-accordion .accordion-button.collapsed,
.faq-accordion .accordion-collapse.collapse {
  background-color: transparent !important;
}
.faq-accordion .accordion-menu-item:hover,
.faq-accordion .accordion-menu-item:focus {
  background-color: $snow !important;
}
.faq-accordion .accordion-button {
  box-shadow: none !important;
}
.faq-accordion .accordion-button svg {
  transform: rotate(-180deg);
}
.faq-accordion .accordion-button.collapsed svg {
  transform: rotate(0deg);
}
.faq-accordion .accordion-menu-item .accordion-button::after {
  background-image: none;
  width: 0;
  padding: 0;
}
.accordion_table {
  border-bottom: 1px solid $sweet-cream !important;
}
.print-div {
  display: none;
}

@media print {
  .print-div {
    min-width: none;
    display: block;
    .not-in-print {
      display: none !important;
    }

    & + * {
      display: none;
    }
  }
}
.checkbox-only {
  .form-check {
    padding-left: 0;
    margin: 0 auto;
  }
  .check-label-pad {
    padding: 0;
  }
}

.table-column-50 {
  tbody td:nth-child(1) {
    width: 40%;
  }
  tbody td:nth-child(2) {
    width: 60%;
  }
}
.table-first-column40 {
  tbody td:nth-child(1) {
    width: 40%;
  }
}
.btn-medium {
  min-height: 2.5rem !important;
}
.granite-gray-color {
  color: $granite-gray;
}

.font-eighteen {
  font-size: 18px;
}
.duo-img {
  max-width: 100px;
}
.list-alpha {
  list-style-type: lower-alpha;
}
.device-management-faq-acc .accordion-menu-item {
  border-bottom: 1px solid $sweet-cream;
}
.device-management-faq-acc .accordion-menu-item .accordion-button {
  color: #03646f !important;
  padding: 1.5rem 0.5rem;
}
.device-management-faq-acc .accordion-button.collapsed,
.device-management-faq-acc .accordion-collapse.collapse {
  background-color: transparent !important;
}
.device-management-faq-acc .accordion-menu-item:hover,
.device-management-faq-acc .accordion-menu-item:focus,
.device-management-faq-acc .accordion-button,
.device-management-faq-acc .accordion-collapse.show {
  background: $snow !important;
}
.device-management-faq-acc .accordion-button {
  box-shadow: none !important;
}
.dm-ques1-ans-fontweight strong {
  @extend .redhat_regular;
}
.duo-iframe {
  border: none;
  width: 100%;
  height: 500px;
}
.header_navlink li {
  padding: 2px 2px 0;
}
.header_navlink .nav-link:focus-visible {
  outline: solid;
  outline-color: #0467eb;
  box-shadow: 0px 0px 1px 0.25rem rgba(5, 93, 242, 0.3);
  border-color: #0467eb;
}

.primary_btn.disabled_btn {
  opacity: 0.4;
}
.primary_btn.disabled_btn:focus,
.primary_btn.disabled_btn:hover,
.primary_btn.disabled_btn:active {
  opacity: 0.37;
  color: #fff !important;
}
.table-custom-style tfoot tr td {
  padding: 1rem;
}
.table-custom-style th:first-child {
  border-top-left-radius: 1rem !important;
}
.table-custom-style th:last-child {
  border-top-right-radius: 1rem !important;
}
@media (max-width: 767px) {
  .group-info-table tbody tr td:nth-of-type(1),
  .group-info-table tbody tr td:nth-of-type(2) {
    display: block;
    width: 100%;
  }
  .group-info-table tbody tr td:nth-of-type(1) {
    padding-bottom: 0;
  }
  .group-info-table tbody tr td:nth-of-type(2) {
    padding-top: 0;
  }
}
footer {
  .footer-links {
    margin-bottom: 1rem !important;
  }
  .copyright-text {
    font-size: 0.875rem !important;
  }
}

.payment-invoice-table tr th {
  padding: 1rem 0.75rem;
}
.payment-invoice-table tr td {
  font-size: 0.875rem;
}
.btn-w-134 {
  min-width: 8.375rem !important;
}
input[type="file"]::-webkit-file-upload-button {
  cursor: pointer;
}

/* Dashboard UI Changes */
.hero-section {
  flex-direction: column;

  @media screen and (min-width: 768px) {
    flex-direction: row-reverse;
    align-items: center;
    border-radius: 1rem;
    background: rgba(34, 112, 102, 0.08);
  }

  .hero-img {
    height: 100%;
    width: 100%;
    @media screen and (min-width: 768px) {
      background: url("../images/dashboard/banner_img.png") no-repeat right
        center;
      border-radius: 0 1rem 1rem 0;
      height: 23.75rem;
      background-size: contain;
      padding-left: 2.5rem;
      display: flex;
      align-items: center;
      padding-top: 0;
    }

    button {
      width: 170px;
    }
  }

  .hero-container {
    gap: 1.5rem;

    .inner-cont {
      gap: 1.5rem;

      @media screen and (min-width: 768px) {
        width: 19rem;
      }

      h2 {
        font-size: 2.25rem;

        @media screen and (min-width: 768px) {
          font-size: 2.5rem;
        }
      }
    }
  }
}

/* Grid Content UI Changes */
.grid-cont,
.plan-grid-cont {
  flex-direction: column;
  margin: 0 1rem;
  position: relative;

  @media screen and (min-width: 768px) {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr);
    margin: 1.5rem 0;
  }

  @media screen and (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }

  .left-border-white,
  .bottom-border-white {
    position: absolute;
    background: $white;
    z-index: 1;
  }

  .left-border-white {
    top: 0;
    left: 0;
    height: 100%;
    width: 1px;
  }

  .bottom-border-white {
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
  }

  .grid-col {
    border-left: 1px solid $sweet-cream;
    border-bottom: 1px solid $sweet-cream;
    gap: 0.75rem;
    padding: 2rem 0.25rem;
    text-decoration: none;
    border-top: 0;
    border-right: 0;
    @extend .redhat_bold;
    color: $primary;
    font-size: 1.125rem;
    font-weight: 700;
    letter-spacing: 0.0225rem;
    line-height: 1.75rem;
    text-align: center;
    display: block;
    span {
      width: 3rem;
      height: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    span svg {
      height: 2.625rem;
    }
  }
}
.plan-grid-cont {
  .grid-col {
    padding: 2rem 1.5rem;
  }
  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (min-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }
}
.global-error-link a {
  color: $global-error-text;
}
.global-error-link svg path {
  fill: $global-error-text;
}

.primary-color {
  color: $secondary !important;
}
.text-error {
  color: $error-text;
}
.heading-with-bg {
  background-color: $panache;
  box-sizing: border-box;
  border-radius: 0.375rem;
  padding: 0.5rem 1.5rem;
  font-size: 0.875rem;
  min-height: 3rem;
  display: flex;
  align-items: center;
}
.accordion-button span {
  width: 1.75rem;
  box-sizing: border-box;
  text-align: center;
}
.accordion-button span svg {
  max-width: 1.25rem;
}
@media screen and (max-width: 991px) {
  .accordion-hamburgermenu .accordion-button span {
    display: none;
  }
}
.secondary_btn svg path {
  stroke: $primary;
}
